import React from "react";
import Header from "../Component/Header/Header";
import View from "../Component/overview/View";
const OverView = () => {
  return (
    <>
      <Header title="Over view" />
      <View />
    </>
  );
};

export default OverView;
