import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './Pages/Dashboard';
import OpenInvoices from './Pages/OpenInvoices';
import OverView from './Pages/OverView';
import RentRoom from './Pages/RentRoom';
import RentParking from './Pages/RentParking';
import ViewClient from './Pages/ViewClient';
import RoomCleaning from './Pages/RoomCleaning';
import ManagePropertie from './Pages/ManageProperties';
import ManageEmployees from './Pages/ManageEmployee';
import BookKeepings from './Pages/BookKeeping';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Dashboard/>} />
      <Route path="/openinvoices" element={<OpenInvoices/>} />
      <Route path="/overview" element={<OverView/>} />
      <Route path="/rentroom" element={<RentRoom/>} />
      <Route path="/rentparking" element={<RentParking/>} />
      <Route path="/viewclient" element={<ViewClient/>} />
      <Route path="/roomcleaning" element={<RoomCleaning/>} />
      <Route path="/manageproperties" element={<ManagePropertie/>} />
      <Route path="/manageemployee" element={<ManageEmployees/>} />
      <Route path="/bookkeeping" element={<BookKeepings/>} />



      

     </Routes>
  </Router>
  );
}

export default App;
