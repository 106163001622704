import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Client = () => {
  return (
    <div className="w-full  py-4 px-10 ">
      <div className="grid grid-cols-12 gap-4">
        <div className=" px-3 py-3 md:col-span-2 col-span-12   mb-1  text-white  bg-gray-400">
        <div className=" py-2 mb-2 rounded-lg text-black">
            <input placeholder=" Search ..." className=" focus:ring-0 focus:outline-none py-1 rounded-lg  w-full  px-2"/>
          </div>
          <div className="px-1 py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center text-sm md:text-[18] font-semibold">
              Kirill Khilko
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test Test</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Max Mustermann</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test2 Test2</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test3 Test3</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test4 Test4</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test5 Test5</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test6 Test6</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test7 Test7</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test8 Test8</h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">Test9 Test9</h1>
          </div>
        </div>
        <div className="bg-gray-400 col-span-10  grid grid-cols-12">
          <div className="py-3 px-5 col-span-4">
            <label
              for="last-name"
              class="block text-xl font-bold leading-8 text-black mb-3"
            >
              {" "}
              Rented{" "}
            </label>
            <Calendar className="py-10" />
            <h1 className="ml-5 pt-4 text-4xl font-bold font-serif">
              Open Invoice
            </h1>
            <hr class="w-60 h-1 mx-auto  bg-black border-0 rounded md:my-10 dark:bg-gray-700" />
            <h1 className="ml-8 text-6xl font-bold font-serif">600CHF</h1>
          </div>
          <div className="py-3 px-5 col-span-4">
            <div class="sm:col-span-3">
              <label
                for="last-name"
                class="block text-lg font-semibold leading-8 text-black"
              >
                Name
              </label>
              <div class="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>
            <div class="sm:col-span-3">
              <label
                for="first-name"
                class="block text-lg font-semibold leading-8 text-black"
              >
                First name
              </label>
              <div class="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-4">
              <label
                for="birthdate"
                class="block text-lg font-semibold leading-8 text-black"
              >
                Birthdate
              </label>
              <div class="mt-2">
                <input
                  id="birthdate"
                  name="birthdate"
                  type="date"
                  autocomplete="Birthdate"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>

            <div class="col-span-full">
              <label
                for="street-address"
                class="block text-lg font-semibold leading-8 text-black"
              >
                ID-Number
              </label>
              <div class="mt-2">
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  autocomplete="street-address"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-2 sm:col-start-1">
              <label
                for="city"
                class="block text-lg font-semibold leading-8 text-black"
              >
                Number plate
              </label>
              <div class="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autocomplete="address-level2"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="py-3 px-5 col-span-4">
            <div class="sm:col-span-3">
              <label
                for="last-name"
                class="block text-lg font-semibold leading-8 text-black"
              >
                Company
              </label>
              <div class="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>
            <div class="sm:col-span-3">
              <label
                for="first-name"
                class="block text-lg font-semibold leading-8 text-black"
              >
                Address
              </label>
              <div class="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-4">
              <label
                for="birthdate"
                class="block text-lg font-semibold leading-8 text-black"
              >
                ZipCode
              </label>
              <div class="mt-2">
                <input
                  id="birthdate"
                  name="birthdate"
                  type="text"
                  autocomplete="Birthdate"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>

            <div class="col-span-full">
              <label
                for="street-address"
                class="block text-lg font-semibold leading-8 text-black"
              >
                State
              </label>
              <div class="mt-2">
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  autocomplete="street-address"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-2 sm:col-start-1">
              <label
                for="city"
                class="block text-lg font-semibold leading-8 text-black"
              >
                Country
              </label>
              <div class="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autocomplete="address-level2"
                  class="block w-full rounded-md border-0 py-3 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                />
              </div>
            </div>
            <div className="flex justify-end gap-3 pe-6">
          <div className="px-2 mt-4 text-[18] mb-4 cursor-pointer  py-2 md:mt-28  text-center w-36 bg-white rounded-lg text-black">
              <button className=" font-semibold">Block</button>
            </div>
            <div className="px-2 mt-4 text-[18] mb-4 cursor-pointer  py-2 md:mt-28  text-center w-36 bg-white rounded-lg text-black">
              <button className=" font-semibold">Save</button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
