import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <>
      <div className="md:px-8 bg-gray-600">
        <Navbar fluid rounded  className="bg-gray-600 py-5">
        <Link to={"/"}><IoMdArrowRoundBack   className="text-white cursor-pointer" size={50}/></Link>
          <Navbar.Brand href="#">
            <span className="self-center text-center whitespace-nowrap text-xl font-semibold dark:text-white text-white">
              {props.title}
            </span>
            </Navbar.Brand>
          <div className="flex md:order-2">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <Avatar
                  alt="User settings"
                  img=""
                  rounded
                />
              }
            >
              <Dropdown.Item>Dashboard</Dropdown.Item>

              <Dropdown.Item>logout</Dropdown.Item>
            </Dropdown>
            {/* <Navbar.Toggle /> */}
          </div>
       
        </Navbar>
      </div>
    </>
  );
};
export default Header;
