import React from 'react'
import Header from '../Component/Header/Header'
import Parking from '../Component/rentparking/Parking'

const RentParking = () => {
  return (
    <>
    <Header title="Rent Parking"/>
    <Parking/>
    </>
  )
}

export default RentParking