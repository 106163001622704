import moment from "moment";
import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import events from "./events";
import { Calendar, momentLocalizer } from "react-big-calendar";

const localizer = momentLocalizer(moment);
const Cleaning = () => (
    
  <div className="px-5 py-10   h-screen text-black   bg-gray-400">
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: "100vh" }}
    />
  </div>
);
export default Cleaning;
