import React from "react";

const ManageEmployee = () => {
  return (
    <div className="w-full  py-4 px-2 md:px-10 ">
      <div className="grid grid-cols-12 gap-4">
        <div className=" px-3 py-3 md:col-span-2 col-span-12   mb-1  text-white  bg-gray-400">
        <div className=" py-2 mb-2 rounded-lg text-black">
            <input placeholder=" Search ..." className=" focus:ring-0 focus:outline-none py-1 rounded-lg  w-full  px-2"/>
          </div>
          <div className="px-1 py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center text-sm md:text-[18] font-semibold">
              Kirill Khilko
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
             Test Test
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Max Mustermann
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Test2 Test2
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Test3 Test3
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Test4 Test4
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Test5 Test5
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Test6 Test6
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Test7 Test7
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Test8 Test8
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Test9 Test9
            </h1>
          </div>
          
        </div>
        <div className="bg-gray-400 md:col-span-10  col-span-12 ">
          <div className="grid grid-cols-12 md:gap-8 gap-6 justify-center  object-contain px-6 py-6">
            <div className=" md:col-span-4 md:mt-10 col-span-12">
               <img src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?t=st=1709377832~exp=1709381432~hmac=d1d93cc53a992d02167c8e16956fef9f9dffaed7bb04ec7a0582db6925dfd6db&w=740" className="md:h-60" alt="ad"/>
            </div>
            <div className=" md:col-span-7 col-span-12 justify-center mt-6 relative">
                <div className="grid md:grid-cols-2 gap-4">
                <div>
                <label className="text-black font-semibold">Name</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Khilko"
                />
              </div>
            <div>
                <label className="text-black font-semibold">Address</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Hadwigstrasse 6"
                />
              </div>
              <div>
                <label className="text-black font-semibold">First Name</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Khilko"
                />
              </div>
              <div className="">
                <label className="text-black font-semibold">Zip Code</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="9400 Rorschach"
                />
              </div>
              <div>
                <label className="text-black font-semibold">Birthday</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="14.07.2003"
                />
              </div>
              <div className="">
                <label className="text-black font-semibold">State</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Sankt Gallen"
                />
              </div>

              <div>
                <label className="text-black font-semibold">ID-Number</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="CH72423k22"
                />
              </div>
              <div className="">
                <label className="text-black font-semibold">Country</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Switzerland"
                />
              </div>
              <div className="">
                <label className="text-black font-semibold">Role</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Administration"
                />
              </div>
             
            </div>
            </div>
          </div>
          <div className="flex justify-end gap-3 pe-6">
          <div className="px-2 mt-4 text-[18] mb-4 cursor-pointer  py-2 md:mt-28  text-center w-36 bg-white rounded-lg text-black">
              <button className=" font-semibold">Block</button>
            </div>
            <div className="px-2 mt-4 text-[18] mb-4 cursor-pointer  py-2 md:mt-28  text-center w-36 bg-white rounded-lg text-black">
              <button className=" font-semibold">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEmployee;
