import React from 'react'
import Header from '../Component/Header/Header'
import Rent from '../Component/rentroom/Rent'

const RentRoom = () => {
  return (
    <>
    <Header title="Rent Room"/>
    <Rent/>
    </>
  )
}

export default RentRoom