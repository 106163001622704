import React from "react";
import { IoIosAddCircleOutline } from "react-icons/io";

const ManageProperties = () => {
  return (
    <div className="w-full  py-4 px-2 md:px-10 ">
      <div className="grid grid-cols-12 gap-4">
        <div className=" px-3 py-5 md:col-span-2 col-span-12    text-white  bg-gray-400">
          <div className="px-1 py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center text-[18] font-semibold">
              9400 Rorschach
            </h1>
          </div>
          <div className="px-1 mt-4 text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Hadwigstrasse 6 | Nr 1
            </h1>
          </div>
          <div className="px-1 mt-4 text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Hadwigstrasse 6 | Nr 2
            </h1>
          </div>
          <div className="px-1 mt-4 text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Hadwigstrasse 6 | Nr 3
            </h1>
          </div>

          <div className="px-1 mt-5  text-center py-1.5 bg-white rounded-xl text-black cursor-pointer">
            <button className="  text-center  font-bold">Add New</button>
          </div>
        </div>
        <div className="bg-gray-400 md:col-span-10  col-span-12 ">
          <div className="grid grid-cols-12 md:gap-14 gap-6 justify-center object-contain px-6 py-6">
            <div className=" md:col-span-5 col-span-12">
              <div>
                <label className="text-black font-semibold">Address</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Hadwigstrasse 6"
                />
              </div>
              <div className="mt-3">
                <label className="text-black font-semibold">Zip Code</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="9400 Rorschach"
                />
              </div>
              <div className="mt-3">
                <label className="text-black font-semibold">State</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Sankt Gallen"
                />
              </div>
              <div className="mt-3">
                <label className="text-black font-semibold">Country</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="Switzerland"
                />
              </div>
              <div className="mt-3">
                <label className="text-black font-semibold">Price</label>
                <br />
                <input
                  className="py-2 w-full px-4 mt-1 rounded-lg placeholder: text-gray-800 focus:ring-0 focus:outline-none  font-semibold"
                  placeholder="700.00 CHF"
                />
              </div>
            </div>
            <div className=" md:col-span-7  col-span-12 justify-center mt-6 relative">
              <div class="relative  w-full md:w-64    h-80">
                <img
                  src="https://img.freepik.com/free-photo/loft-living-room-interior-design_53876-145499.jpg?t=st=1709382810~exp=1709386410~hmac=e49847019327e6dff7cbd172b96a6450fa627b340d72d39f30646bd48accaed4&w=740"
                  class="absolute w-full  blur-sm  h-full md:inset-x-24 z-10 rounded-lg"
                  alt=" 1"
                />
                <img
                  src="https://img.freepik.com/free-photo/green-sofa-white-living-room-with-free-space_43614-834.jpg?t=st=1709382783~exp=1709386383~hmac=30db40eeb5c5a4ebf56d9c45ba3fc3f9f08395f187912e6a7669f3bc4d75d957&w=740"
                  class="absolute w-full blur-sm  h-full  md:inset-x-10  z-20 rounded-lg"
                  alt=" 2"
                />
              
                <img
                  src="https://img.freepik.com/free-photo/armchair-green-living-room-with-copy-space_43614-910.jpg?t=st=1709382758~exp=1709386358~hmac=6428ce32f5bcab05aea2d5977aa3e5ee984eec6da3ac476550146373f9f5f84d&w=740"
                  class="absolute  w-full  h-full md:inset-x-6 z-30 rounded-lg"
                  alt=" 3"
                />
                 <div className="absolute bottom-0 md:right-[-80px] z-40">

<IoIosAddCircleOutline
size={30}
/>
</div>
              </div>
             
            </div>
          </div>
          <div className="flex justify-end pe-6">
            <div className="px-2 mt-4 text-[18] mb-4  py-2 md:mt-28  text-center w-36 bg-white rounded-lg text-black cursor-pointer">
              <button className=" font-semibold">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageProperties;
