import React from "react";
const data = [
    {
      date: "25.02.2025",
      name: "Kirilli Khilko",
      secondName: "Car Refill",
      Degree: "300CHF",
    },
    {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      }, 
    {
      date: "25.02.2025",
      name: "Kirilli Khilko",
      secondName: "Car Refill",
      Degree: "300CHF",
    },
    {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      }, 
    {
      date: "25.02.2025",
      name: "Kirilli Khilko",
      secondName: "Car Refill",
      Degree: "300CHF",
    },
    {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      }, 
      
      {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      }, {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      },  {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      },  {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      },  {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      },  {
        date: "25.02.2025",
        name: "Kirilli Khilko",
        secondName: "Car Refill",
        Degree: "300CHF",
      }, 
  ];
  
const BookKeeping = () => {
  return (
    <div className=" py-4 px-2 md:px-10 ">
      <div className="grid grid-cols-12 gap-4">
        <div className="  px-3 py-3 md:col-span-2 col-span-12    mb-1  text-white  bg-gray-400">
      
        <div className="px-1 mt-4 text-sm  md:text-sm lg:text-lg  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center md:text-sm lg:text-lg font-semibold">
              9'940.00 CHF
            </h1>
          </div>
       
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Deposit
            </h1>
          </div>
          <div className="px-1 mt-4 text-sm md:text-[18]  py-2 bg-white rounded-lg text-black cursor-pointer">
            <h1 className="text-center font-semibold">
              Withdraw
            </h1>
          </div>
          
        </div>
        <div className="bg-gray-400 md:col-span-10  col-span-12 ">
        <table className="w-full px-4 text-sm text-left rtl:text-right  dark:text-gray-400">

        <thead className="  rounded pb-2 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className=" lg:px-10 px-2 py-3">
              Date
            </th>
            <th scope="col" className=" lg:px-10 px-2 py-3">
              Employee
            </th>
            <th scope="col" className=" lg:px-10 px-2 py-3">
              Reason
            </th>
            <th scope="col" className=" lg:px-10 px-2  py-3 ">
              Amount
            </th>
          </tr>
        </thead>

        <tbody>
          {data?.map((e) => {
            return (
              <>
                <tr className=" border-b bg-slate-100 dark:bg-gray-800 dark:border-gray-700">
                  <td className="lg:px-10 px-2 pl-2 py-4 font-medium text-black">
                    {e.date}
                  </td>
                  <td className="lg:px-10 px-2 py-4 font-medium text-gray-900">
                    {e.name}
                  </td>
                  <td className="lg:px-10 px-2 py-4 font-medium text-gray-900">
                    {e.secondName}
                  </td>
                  <td className="lg:px-10 px-2 py-4  text-green-500 font-medium ">
                    {e.Degree}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
         
        </div>
      </div>
    </div>
  );
};

export default BookKeeping;
