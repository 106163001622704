const data = [
  {
    date: "25.02.2025",
    name: "Kirilli",
    secondName: "Car Refill",
    Degree: "300CHF",
  },
  {
    date: "25.02.2025",
    name: "Kirilli",
    secondName: "Car Refill",
    Degree: "100CHF",
  },
  {
    date: "25.02.2025",
    name: "Kirilli",
    secondName: "Car Refill",
    Degree: "30CHF",
  },
  {
    date: "25.02.2025",
    name: "Kirilli",
    secondName: "Car Refill",
    Degree: "10CHF",
  },
];

const OpenInvoices = () => {
  return (
    <div className="px-4 mt-4">
      <table className="w-full px-4 text-sm text-left rtl:text-right  dark:text-gray-400">
        <tr className="bg-gray-200 ">
          <th scope="col" className=" lg:px-24 pl-2 py-3">
            25.02.2025
          </th>
          <th scope="col" className=" lg:px-24 py-3">
            Kirilli
          </th>
          <th scope="col" className=" lg:px-24 py-3">
            Car Refill
          </th>
          <th scope="col" className=" lg:px-24  py-3  text-red-500">
            160CHF
          </th>
        </tr>
        <thead className="  rounded pb-2 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className=" lg:px-24 py-3">
              Date
            </th>
            <th scope="col" className=" lg:px-24 py-3">
              Client
            </th>
            <th scope="col" className=" lg:px-24 py-3">
              Reason
            </th>
            <th scope="col" className=" lg:px-24  py-3 ">
              Amount
            </th>
          </tr>
        </thead>

        <tbody>
          {data?.map((e) => {
            return (
              <>
                <tr className=" border-b bg-slate-100 dark:bg-gray-800 dark:border-gray-700">
                  <td className="lg:px-24 pl-2 py-4 font-medium text-black">
                    {e.date}
                  </td>
                  <td className="lg:px-24 py-4 font-medium text-gray-900">
                    {e.name}
                  </td>
                  <td className="lg:px-24 py-4 font-medium text-gray-900">
                    {e.secondName}
                  </td>
                  <td className="lg:px-24 py-4  text-green-500 font-medium ">
                    {e.Degree}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      <div>
        <div className="flex flex-wrap  justify-end  bg-slate-100 py-3 pe-4 px-3">
          <div className="mt-2">
            <button className="px-12  md:rounded-l-lg lg:rounded-l-lg xl:rounded-l-lg w-full  md:w-48 py-2 bg-white text-black font-semibold">
              150 CHF
            </button>
            <button className="px-12 mt-3 md:mt-0  md:rounded-r-lg  lg:rounded-r-lg xl:rounded-r-lg w-full md:w-48 py-2 bg-gray-200 text-black  font-semibold">
              Pay
            </button>
          </div>
        </div>
      </div>

      <table className="w-full px-4 mt-4 text-sm text-left rtl:text-right    dark:text-gray-400">
        <tr className="bg-gray-200 ">
          <th scope="col" className=" lg:px-24 pl-2 py-3">
            25.02.2025
          </th>
          <th scope="col" className=" lg:px-24 py-3">
            Kirilli
          </th>
          <th scope="col" className=" lg:px-24 py-3">
            Payback
          </th>
          <th scope="col" className=" lg:px-24  py-3  text-red-500">
            3000CHF
          </th>
        </tr>
      </table>
    </div>
  );
};

export default OpenInvoices;
