import Header from "../Component/Header/Header"
import ManageProperties from "../Component/manageproperties/ManageProperties"
const ManagePropertie=()=>{

    return(
        <>
        <Header title="Manage Properties"/>
        <ManageProperties/>
        </>
    )
}
export default ManagePropertie