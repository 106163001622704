import { FaDoorOpen } from "react-icons/fa6";
import { FaKey } from "react-icons/fa";
import { FaParking } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";
import { MdOutlineMan } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";
import { BsBagFill } from "react-icons/bs";
import { Link } from "react-router-dom";
const DashboardContent = () => {
  const data = [
    {
      title: "OverView",
      status: "Rented",
      icon: <FaDoorOpen />,
      number: "59",
      Path: "/overview",

    },
    {
      title: "Rent Room",
      status: "Available",
      icon: <FaKey />,
      number: "14",
      Path: "/rentroom",

    },
    {
      title: "Rent Parking",
      status: "Available",
      icon: <FaParking />,
      number: "14",
      Path: "/rentparking",

    },
    {
      title: "Rent Cleaning",
      status: "Cleaning",
      icon: <MdCleaningServices />,
      number: "5",
      Path: "/roomcleaning",

    },
    {
      title: "View Client",
      icon: <MdOutlineMan />,
      status: "public",
      Path: "/viewclient",

    },
  ];

  const data2 = [
    {
      title: "Open Invoices",
      icon: <FaFileInvoiceDollar />,
      number: "2",
      Path: "/openinvoices",
    },
    {
      title: "Book Keeping",
      icon: <FaBook />,
      Path: "bookkeeping",
    },

    {
      title: "View Client",
      icon: <FaRankingStar />,
    //   Path: "#",
    },
  ];

  const data3 = [
    {
      title: "Manage Properties",
      icon: <FaBuilding />,
      path: "/manageproperties",
    },

    {
      title: "Manage Employees",
      icon: <BsBagFill />,
      path: "/manageemployee",
    },
  ];
  return (
    <>
      <div className="py-6 px-4">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-6 lg:px-6 md:px-4 md:grid-cols-3 sm:grid-cols-2">
          {data.map((val) => {
            return (
              <Link to={`${val.Path}`}>
              <>
              <div
                className="px-8  rounded-lg py-4  h-48  cursor-pointertransform hover:translate-y-2 hover:shadow-xl transition duration-500"
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              >
                <h1 className=" text-xl font-semibold">{val.title}</h1>
                <div className="mt-14">
                  <h1 className=" ">{val.status}</h1>
                  <div className="flex gap-6 mt-1 items-center">
                    <h1 className=" text-3xl">{val.icon}</h1>
                    <h1 className=" text-3xl">{val.number}</h1>
                  </div>
                </div>
              </div>
              </>
              </Link>
            );
          })}
        </div>

        <h1 className="ml-6 mt-6 font-semibold text-xl">Accounting</h1>
        <div className="grid grid-cols-1 mt-2 gap-6  lg:grid-cols-6 lg:px-6 md:px-4 md:grid-cols-3 sm:grid-cols-2">
          {data2.map((val) => {
            return (
              <Link to={`${val.Path ?? "#"}`}>
                <div
                  className="px-8 rounded-lg py-3 h-48  cursor-pointer  transform hover:translate-y-2 hover:shadow-xl transition duration-500"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                >
                  <h1 className=" text-xl font-semibold">{val.title}</h1>
                  <div className="mt-14">
                    <div className="flex gap-6 mt-1 items-end">
                      <h1 className=" text-3xl">{val.icon}</h1>
                      <h1 className=" text-3xl">{val.number}</h1>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

        <h1 className="ml-6 mt-6 font-semibold text-xl">Administration</h1>
        <div className="grid grid-cols-1 mt-2 gap-6 lg:grid-cols-6 lg:px-6 md:px-4 md:grid-cols-3 sm:grid-cols-2">
          {data3.map((val) => {
            return (
              <Link to={`${val.path}`}>
                <div
                  className="px-8 rounded-lg py-2 h-48  cursor-pointer bg-white transform hover:translate-y-2 hover:shadow-xl transition duration-500"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                >
                  <h1 className=" text-xl font-semibold">{val.title}</h1>
                  <div className="mt-14">
                    <h1 className=" ">{val.status}</h1>
                    <div className="flex gap-6 mt-1 items-center">
                      <h1 className=" text-3xl">{val.icon}</h1>
                      <h1 className=" text-3xl">{val.number}</h1>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DashboardContent;
