import Header from "../Component/Header/Header"
import OpenInvoicesContent from "../Component/openinvoices/OpenInvoicescontent"

 const OpenInvoices=()=>{

    return(
        <>
        <Header title="OpenInvoices"/>
        <OpenInvoicesContent/>
        </>
    )
}
export default OpenInvoices