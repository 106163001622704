import Header from "../Component/Header/Header"
import ManageEmployee from "../Component/manageemployee/ManageEmployee"
const ManageEmployees=()=>{

    return(
        <>
        <Header title="Manage Employee"/>
        <ManageEmployee/>
        </>
    )
}
export default ManageEmployees