import React from 'react'
import Header from '../Component/Header/Header'
import Cleaning from '../Component/roomcleaning/Cleaning'

const RoomCleaning = () => {
  return (
    <>
    <Header title="Room cleaning"/>
    <Cleaning/>
    </>
  )
}

export default RoomCleaning