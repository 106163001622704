import Header from "../Component/Header/Header"
import DashboardContent from "../Component/Dashboard/DashboardContent"
const Dashboard=()=>{

    return(
        <>
        <Header title="Dashboard"/>
        <DashboardContent/>
        </>
    )
}
export default Dashboard