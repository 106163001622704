
import Header from "../Component/Header/Header"
import BookKeeping from "../Component/bookkeeping/BookKeeping"
const BookKeepings=()=>{

    return(
        <>
        <Header title="BookKeeping"/>
        <BookKeeping/>
        </>
    )
}
export default BookKeepings