import React from 'react'
import Header from '../Component/Header/Header'
import Client from '../Component/viewclient/Client'

const ViewClient = () => {
  return (
    <>
    <Header title="View client"/>
    <Client/>
    </>
  )
}

export default ViewClient